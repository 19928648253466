import React from 'react';
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MedModal from '../Modal';
import { toRem, breakpoints } from "@utils/mixins"
import { setColors } from "@theme/colors"
import styled from "@emotion/styled"

const Headline = styled.h5`
  font-size: ${toRem(20)};
  color: ${setColors.grey.darker};
  font-weight: 700;
  text-align: center;
  margin: 0 0 ${toRem(15)} 0;
  text-transform: none;
  line-height: ${toRem(24)};
  margin-top: ${toRem(12)};

  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(20)};
    line-height: ${toRem(24)};
  }

  @media ${breakpoints.largeDesktop} {
    font-size: calc(0.8vw + ${toRem(16)});
    line-height: calc(0.8vw + ${toRem(16)});
    margin-bottom: 1vw;
  }
`

 const Text = styled.p`
   font-size: ${toRem(14)};
   color: ${setColors.grey.darker};
   font-weight: 400;
   text-align: center;
   margin: 0 0 ${toRem(15)} 0;
   text-transform: none;
   line-height: ${toRem(20)};
   @media ${breakpoints.bigDesktop} {
     font-size: ${toRem(16)};
   }
   @media ${breakpoints.largeDesktop} {
     font-size: calc(0.5vw + ${toRem(14)});
     line-height: calc(0.7vw + ${toRem(16)});
   }
 `

const ReadMoreCard = () => {

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      maxWidth: 1600,
      background: "red",
    },
    paper: {
      padding: theme.spacing(2, 2),
      textAlign: "center",
      color: setColors.white,
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      display: "flex",
      maxWidth: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      /* eslint-disable */
      ["@media(min-width: 1921px)"]: {
        padding: "2vw 2vw",
        minHeight: toRem(330),
      },
      /* eslint-enable */
      [theme.breakpoints.down("md")]: {
        maxWidth: toRem(430),
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: toRem(330),
      },
    },
    text: {
      color: setColors.grey.darker,
    },
  }))
const classes = useStyles()
return (
  <Paper className={classes.paper} elevation={3} square={false}>
    <Headline>Our mission</Headline>
    <Text>
      We are developing a unique interactive online community offering proactive
      support and assistance to men with prostate cancer and their families.
      This community will help them deal with the impact the condition has on
      their personal and social lives.
    </Text>
    <MedModal />
  </Paper>
)
}

export default ReadMoreCard
