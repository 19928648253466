import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { setColors } from "@theme/colors"
import Button from "@material-ui/core/Button"
import { toRem } from "@utils/mixins"
import { Box } from "@material-ui/core"

const StyledButton = withStyles(theme => ({
  root: {
    borderRadius: 0,
    border: 0,
    color: setColors.blue,
    height: 47,
    boxShadow: "none",
    fontSize: toRem(14),
    background: setColors.white,
    /* eslint-disable */
    ["@media(min-width: 1921px)"]: {
      fontSize: "calc(0.5vw + 16px)",
      height: 60,
      marginTop: "0.5vw",
    },
    /* eslint-enable */
    "&:hover": {
      background: setColors.white,
      color: setColors.blue,
      opacity: "0.8",
      boxShadow: "none",
    },
    "&:click": {
      background: setColors.white,
    },
  },
  label: {
    textTransform: "uppercase",
  },
}))(Button)

export default function MedModal() {
  const [open, setOpen] = React.useState(false)

const handleOpen = (event) => {
  event.preventDefault()
  setOpen(true)
}

  const handleClose = event => {
    event.preventDefault()
    setOpen(false)
  }

  const useStyles = makeStyles(theme => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "none",
      padding: theme.spacing(3, 3),
      outline: "none",
      margin: "auto",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
      width: "90%",
      height: "auto",
      maxWidth: toRem(1200),
      /* eslint-disable */
      ["@media(min-width: 1921px)"]: {
        maxWidth: "60%",
      },
      /* eslint-enable */
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
        maxWidth: "100%",
      },
    },
    closeIcon: {
      cursor: "pointer",
      textAlign: "right",
      float: "right",
      /* eslint-disable */
      ["@media(min-width: 1921px)"]: {
        width: "1vw",
      },
      /* eslint-enable */
    },
    contentWrapper: {
      padding: "30px",
      width: "100%",
      height: "100%",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    headline: {
      color: setColors.blue,
      textAlign: "left",
      marginLeft: 0,
      fontSize: toRem(34),
      lineHeight: toRem(36),
      fontWeight: 400,
      marginBottom: toRem(40),
      /* eslint-disable */
      ["@media(min-width: 1921px)"]: {
        // fontSize: "4vh",
        fontSize: " calc(1.4vw + 10px)",
        // lineHeight: " calc(0.5vw + 14px)",
      },
      /* eslint-enable */
      [theme.breakpoints.down("xs")]: {
        fontSize: toRem(20),
        fontWeight: 500,
        lineHeight: toRem(24),
        marginBottom: toRem(37),
      },
    },
    text: {
      color: setColors.black.full,
      fontWeight: 400,
      textAlign: "left",
      lineHeight: toRem(24),
      fontSize: toRem(16),
      /* eslint-disable */
      ["@media(min-width: 1921px)"]: {
        fontSize: " calc(0.5vw + 10px)",
        lineHeight: " calc(0.5vw + 16px)",
      },
      /* eslint-enable */
      [theme.breakpoints.down("xs")]: {
        lineHeight: toRem(20),
        fontSize: toRem(14),
      },
    },
  }))

    const classes = useStyles()


  return (
    <div>
      <StyledButton
        variant="contained"
        size="large"
        onClick={handleOpen}
        href="#"
        // color="white"
        disableRipple={true}
      >
        Read More
      </StyledButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            background: "rgba(250,250,250,0.8)",
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              aria-hidden="true"
              onClick={handleClose}
              className={classes.closeIcon}
            >
              <img width="100%" src="images/close-icon.png" alt="Close" />
            </div>
            <Box className={classes.contentWrapper}>
              <h2 className={classes.headline}>Our mission</h2>
              <div className={classes.text}>
                <p>
                  We are developing a unique online community which, driven by
                  specialized medical practitioners and supported by a highly
                  sophisticated algorithm, has the capability of matching
                  members who have identical or similar diagnostic and treatment
                  journeys. This allows personal interaction and information
                  exchange amongst members, their families and, if required,
                  appropriate medical professionals.
                </p>
                <p>
                  The access and the information provided or exchanged on the
                  platform meet the highest security standards and are strictly
                  confidential.
                </p>
                <p>
                  The community provides strong support and comfort to patients
                  and family members alike. In addition to the unique matching
                  service, the platform will include centralized access to
                  diagnostic reports, introduction to suitable clinical trials
                  and the possibility of facilitating virtual consultations with
                  relevant medical and surgical services.
                </p>
              </div>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
