import React from 'react'
import { toRem, breakpoints } from "@utils/mixins";
import { setColors } from "@theme/colors";
import styled from "@emotion/styled";
import Grid from "@material-ui/core/Grid";

export const Container = styled.div`
  max-width: ${toRem(1440)};
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  @media ${breakpoints.largeDesktop} {
    max-width: 80%;
    min-width: 50%;
    margin: auto;
  }

  /* @media ${breakpoints.largeDesktop} {
    width: ${toRem(630)};
    max-width: 100%;
    margin: auto;
  } */
`

export const ButtonContainer = styled.div`
  max-width: 100%;
  width: ${toRem(430)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  @media ${breakpoints.largeDesktop} {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
`
  export const ButtonFull = styled.a`
    border-radius: 4;
    border: 0;
    color: white;
    height: 47;
    box-shadow: none;
    margin-bottom: ${toRem(10)};
    margin-top: ${toRem(0)};
    background: ${setColors.blue};
    font-weight: 500;
    font-size: ${toRem(20)};
    max-width: 100%;
    width: ${toRem(430)};
    padding: 10px;

    @media ${breakpoints.largeDesktop} {
      font-size: calc(1vw + 10px);
      line-height: calc(1vw + 10px);
      height: auto;
      width: 50%;
      padding: calc(0.5vw + 10px);
      margin-bottom: calc(0.1vw + 10px);
    }

    &:hover {
      background: ${setColors.blue};
      color: #fff;
      opacity: 0.8;
      box-shadow: none;
    }

    @media ${breakpoints.mobileonly} {
      max-width: 100%;
      width: ${toRem(430)};
      max-width: 100%;
    }
  `

    export const ButtonOutline = styled.a`
      border-radius: 4;
      border: 1px solid ${setColors.blue};
      color: ${setColors.blue};
      height: 47;
      box-shadow: none;
      margin-bottom: ${toRem(20)};
      font-weight: 500;
      font-size: ${toRem(20)};
      max-width: 100%;
      width: ${toRem(430)};
      padding: 10px;
      transition: all .22s;

      @media ${breakpoints.largeDesktop} {
        font-size: calc(1vw + 10px);
        line-height: calc(1vw + 10px);
        height: auto;
        width: 50%;
        padding: calc(0.5vw + 10px);
        margin-bottom: calc(0.2vw + 10px);
      }

      &:hover {
        background: ${setColors.blue};
        color: #fff;
        opacity: 0.8;
        box-shadow: none;
      }

      @media ${breakpoints.mobileonly} {
        max-width: 100%;
        width: ${toRem(430)};
        max-width: 100%;
      }
    `

export const Headline = styled.h1`
  font-size: ${toRem(34)};
  color: ${setColors.grey.darker};
  font-weight: 700;
  text-align: center;
  margin: ${toRem(15)} 0 ${toRem(8)} ${toRem(15)};
  text-transform: none;

  span {
    display: block;
  }

  @media ${breakpoints.tablet} {
    font-size: ${toRem(48)};
    font-height: ${toRem(56)};
    font-weight: 700;
    margin: 0 0 ${toRem(15)} 0;
    span {
      display: inline-block;
    }
  }

  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(60)};
    line-height: ${toRem(72)};
    font-weight: 700;
    margin: 0 0 ${toRem(15)} 0;
    span {
      display: inline-block;
    }
  }

  @media ${breakpoints.largeDesktop} {
    font-size: calc(1vw + ${toRem(38)});
    line-height: calc(1vw + ${toRem(30)});
    margin: 0 0 ${toRem(15)} 0;
  }
`

export const Subtitle = styled.div`
  font-size: ${toRem(16)};
  color: ${setColors.grey.darker};
  font-weight: 400;
  line-height: ${toRem(24)};
  text-align: center;
  margin: 0 auto ${toRem(17)} auto;
  max-width: 600px;

  p {
    margin-bottom: ${toRem(20)};
  }

  @media ${breakpoints.tablet} {
    font-size: ${toRem(16)};
    line-height: ${toRem(24)};
    max-width: 600px;

    p {
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(16)};
    line-height: ${toRem(24)};
    max-width: 100%;
    max-width: 700px;

    p {
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.largeDesktop} {
    font-size: calc(0.7vw + ${toRem(10)});
    line-height: calc(0.7vw + ${toRem(14)});
    margin: 0 auto ${toRem(15)} auto;
    padding: ${toRem(10)} ${toRem(30)};
    max-width: 100%;
  }
`

const Intro = () => {



return (
  <Container>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
    >
      <Grid item xs={12}>
        <Headline>
          Welcome to <span>MedTech</span>
        </Headline>
      </Grid>
      <Grid item xs={12}>
        <Subtitle>
          <p>
            Help develop our community for the benefit of prostate cancer
            sufferers and their families. Whether you are or have been a patient
            yourself, or have assisted a family member or friend, our survey
            will take you no longer than 10 minutes.
          </p>
        </Subtitle>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <ButtonContainer>
          <ButtonFull
            href="https://bit.ly/MedTechPatientSurvey"
            target="_blank"
          >
            I am a patient or survivor
          </ButtonFull>
          <ButtonOutline
            href="https://bit.ly/MedTechFamilySurvey"
            target="_blank"
          >
            I am a family member or friend
          </ButtonOutline>
        </ButtonContainer>
      </Grid>
    </Grid>
  </Container>
)
}

export default Intro
