import React from 'react';
import CreateHead from "@components/CreateHead";
import Logo from "@components/Logo";
import Cards from "@components/Cards"
import Intro from "@components/Intro";


export default function Home() {
  return (
    <>
      <CreateHead title={"Welcome to Medtech"} />
      <Logo laptop={100} desktop={200} big={240} />
      <Intro />
      <Cards />
    </>
  )
}
