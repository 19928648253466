import React from "react"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import { toRem, breakpoints } from "@utils/mixins"
import { setColors } from "@theme/colors"
import styled from "@emotion/styled"
import { Link } from "gatsby"

 const Text = styled.h4`
   font-size: ${toRem(34)};
   color: ${setColors.blue};
   font-weight: 700;
   text-transform: none;
   line-height: ${toRem(36)};
   margin-bottom: ${toRem(10)};

   @media ${breakpoints.tablet} {
     font-size: ${toRem(34)};
     line-height: ${toRem(36)};
   }

   @media ${breakpoints.bigDesktop} {
     font-size: ${toRem(48)};
     line-height: ${toRem(52)};
   }

   @media ${breakpoints.largeDesktop} {
     font-size: calc(1vw + 34px);
     line-height: calc(1vw + 34px);
   }
 `

 const Image = styled.img`
   width: ${toRem(114)};

   @media ${breakpoints.largeDesktop} {
     width: calc(5vw + 114px);
   }
 `

const ReadMoreCard = () => {

    const useStyles = makeStyles(theme => ({
      root: {
        flexGrow: 1,
        maxWidth: 1600,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        background: setColors.grey.lighter,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "100%",
        /* eslint-disable */
        ["@media(min-width: 1921px)"]: {
          padding: "2vw 2vw",
          minHeight: "330px",
        },
        /* eslint-enable */
        [theme.breakpoints.down("md")]: {
          maxWidth: toRem(430),
        },
        [theme.breakpoints.down("xs")]: {
          minHeight: toRem(330),
        },
      },
    }))

      const classes = useStyles()

  return (
    <>
      <Link to="/about">
        <Paper className={classes.paper} elevation={3} square={false}>
          <Text>
            Read our <br />
            story
          </Text>
          <Image src="images/blue_ribbon3.png" alt="MedTech" />
        </Paper>
      </Link>
    </>
  )
}

export default ReadMoreCard
