import React from 'react'
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import ReadMoreCard from "./ReadMoreCard"
import ReadOurStory from './ReadOurStory'
import { toRem } from "@utils/mixins"


const Cards = () => {

  const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: toRem(10),
      minHeight: "200px",
      minWidth: "auto",
      width: "100%",
      maxWidth: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      /* eslint-disable */
      ["@media(min-width: 1440px)"]: {
        maxWidth: "80%",
        // minWidth: "80%",
      },
      ["@media(min-width: 1921px)"]: {
        minWidth: "80%",
      },
      ["@media(max-width: 600px)"]: {
        maxWidth: "100%",
        // minWidth: "80%",
      },
      // ["@media (min-width: 1500px) and (max-width: 1920px)"]: {
      //   maxWidth: "80%",
      //   marginLeft: "auto",
      //   marginRight: "auto",
      // },
      /* eslint-enable */
    },
    gridRead: {
      alignSelf: "stretch",
      width: "100%",
      minHeight: "200px",
    },
  }))

  const classes = useStyles()
return (
  <Container  className={classes.container}>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
      className={classes.container}
    >
      <Grid item sm={12} md={6} lg={6} xl={6} className={classes.gridRead}>
        <ReadMoreCard />
      </Grid>
      <Grid item sm={12} md={6} lg={6} xl={6} className={classes.gridRead}>
        <ReadOurStory />
      </Grid>
    </Grid>
  </Container>
)
}

export default Cards
